import { addDays, addMonths, addWeeks, addYears, differenceInCalendarDays, format } from 'date-fns'
import type { TFunction } from 'i18next'
import { newGuid } from './generates'
import type { Message } from './models'
import { StrategyType } from './types'


export enum DeliveryType {
  Immediate2Days = 'immediate2days',
  ImmediateWeek = 'immediateWeek',
  Immediate2Weeks = 'immediate2Weeks',
  ImmediateMonth = 'immediateMonth',
  ImmediateYear = 'immediateYear',
  ImmediateNoExpiry = 'immediateNoExpiry',
  CustomDateTime = 'customDateTime'
}
export const getTitleForDeliveryType = (type: DeliveryType, customExpiryDateTime: Date | null, customScheduleDateTime: Date | null, t: TFunction) => {
  switch (type) {
    case DeliveryType.Immediate2Days: return t`titleImmediate2Days`
    case DeliveryType.ImmediateMonth: return t`titleImmediatelyMonth`
    case DeliveryType.ImmediateYear: return t`titleImmediatelyYear`
    case DeliveryType.ImmediateNoExpiry: return t`titleImmediatelyNoExpiry`
    case DeliveryType.CustomDateTime: return customExpiryDateTime ? (customScheduleDateTime ? t('messagesDateTimeSelector:titleCustom', { schedule: format(customScheduleDateTime, 'dd.MM.yyyy'), days: differenceInCalendarDays(customExpiryDateTime, customScheduleDateTime || Date.now()) }) : t('title', { days: differenceInCalendarDays(customExpiryDateTime, Date.now()) })) : t`pleaseSelectCustomDate`
    case DeliveryType.Immediate2Weeks: return t`titleImmediately2Weeks`
    case DeliveryType.ImmediateWeek: return t`titleImmediatelyWeek`
    default: 
      return t`titleImmediate2Days`
  }
}

export const getExpiryByDeliveryType = (deliveryType: DeliveryType, customExpiryDateTime: Date | null) => {
  switch (deliveryType) {
    case DeliveryType.Immediate2Days: return addDays(new Date, 2)
    case DeliveryType.CustomDateTime: return customExpiryDateTime
    case DeliveryType.ImmediateMonth: return addMonths(new Date(), 1)
    case DeliveryType.ImmediateYear: return addYears(new Date(), 1)
    case DeliveryType.ImmediateNoExpiry: return null
    case DeliveryType.Immediate2Weeks: return addWeeks(new Date(), 2)
    case DeliveryType.ImmediateWeek:
    default:
      return addDays(new Date(), 7)
  }
}
const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const extractMessagePayload = (message: Message) => {
  let response = {
    text: '',
    fileUrl: undefined,
    mediaType: undefined,
    survey: undefined,
    videoThumbnailUrl: undefined,
    videoThumbnailType: undefined,
    // TODO: add survey parse results
  }
  const strategy = message.msg_strategy
  if (strategy === StrategyType.media) {
    if (IsJsonString(message.payload)) {
      const data = JSON.parse(message.payload)
      if(data?.files?.length && 
        data?.files?.length == 2 && 
        data.files[0].fileUrl != null && 
        data.files[1].fileUrl != null &&
        data.files[1].type == "VIDEO"
      ) {
        response.text = data.text
        response.fileUrl = data.files[1].fileUrl
        response.mediaType = data.files[1].type
        response.videoThumbnailUrl = data.files[0].fileUrl
        response.videoThumbnailType = data.files[0].type
      } else if (data?.files?.length && data.files[0].fileUrl != null) {
        response.text = data.text
        response.fileUrl = data.files[0].fileUrl
        response.mediaType = data.files[0].type
      }
    } else {
      response.text = message.payload

    }

  } else if (/^QS/.test(strategy)) {
    const data = JSON.parse(message.payload)
    data?.answers?.forEach(a => a.id = newGuid())
    response.survey = data
    response.text = data.question
  } else {
    response.text = message.payload
  }
  return response
}
