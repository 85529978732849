import axios, { AxiosInstance, AxiosResponse } from 'axios'

import { snackBar } from 'store'
import userStore from 'store/user.store'
import type { UserMessage, User, Message } from 'utils/models'
import { ID, UserGroup, SendApiUser, SendApiUserReq, MessageOp, Group, PayloadRequest, CreateCommunityReq, UserGroupUpdateReq, UserGroupCreateReq, MessageResponseOp, StatusType, GroupType, InfoType, ProviderEnum, Info } from 'utils/types'
import { intToBool } from 'utils/converts'
import { newGuid } from 'utils/generates'
import { ModuleType } from 'utils/types'
import { RelateUserGroupOp, CreateOrUpdateUserReq, OperationType } from 'utils/operation'

export const goEndPoints = {
  login: () => '/api/users/login',
  verify: () => '/api/users/verify',
  getMessages: () => '/api/entity/messages',
  getMessageDetails: (number: number) => `/api/entity/message/${number}`,
  getInfo: () => '/api/entity/info',
  getUtc: () => '/api/entity/datetime',
  getLabels: () => '/api/entity/labels',
  createMessage: () => '/api/entity/message',
  getCommunities: () => '/api/entity/communities',
  logout: () => '/api/users/logout/4',
  refreshToken: () => '/api/users/auth/V-13.3.6-WMC/4',
  getConcileUsers: (concileNumber: string) => `/sapi/entities/user/${concileNumber}`,
}
export const endpoint = {
  getGroupUsers: () => `/getGroupUsers`,
  getGroupMessages: () => `/getGroupMessages`,
  getGroups: () => `/getGroups`,
  getCommunities: () => `/getCommunities`,
  getCommunitiesUsers: () => `/getCommunitiesUsers`,
  getCommunityMessages: () => `/getCommunityMessages`,
  getOpenSupportCalls: () => `/getOpenSupportCalls`,
  getSupportGroups: () => `/getSupportGroups`,
  setUserCommunity: () => '/setUserCommunity',
  Sync5: () => '/Sync5'
}

type Payload = { [key: string]: string | number }
enum Method {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
}
const mapGroupsDescription = (groups: Array<any>, groupType: GroupType) => {
  if (groups?.length > 0) {
    const groupsMap = groups.map<UserGroup>((g: any) => {
      return ({
        group: {
          community_id: g.community_id,
          id: g.id,
          description: /^{"/.test(g.description) ? JSON.parse(g.description) : g.description,
          name: g.name,
          icon: g.icon,
          members: g.members,
          orgainzers: g.orgainzers,
          group_is_member: g.group_is_member != null ? g.group_is_member : false,
          group_is_subscriber: g.group_is_subscriber != null ? g.group_is_subscriber : false,
          group_is_favourite: g.group_is_favourite != null ? g.group_is_favourite : false,
          group_is_sender: g.group_is_sender != null ? g.group_is_sender : false,
          duplex: g.duplex,
          is_organizer: g.is_organizer,
          parent_id: g.parent_id,
          type: groupType

        },
        community: g.community_id,
        is_favourite: g.is_favourite,
        is_organizer: g.is_organizer,
        is_sender: g.is_sender,
        is_subscriber: g.is_subscriber,
        is_member: g.is_member,
        user: g.user_id,
        parent_id: g.parent_id
      })
    })
    return groupsMap
  }
  return []
}

const PROTOCOL_VERSION = 'V-1.0-WMC'
class Api {

  constructor(old: AxiosInstance, client: AxiosInstance, oldMessage: AxiosInstance, goApi: AxiosInstance) {
    this.old = old
    this.client = client
    this.oldMessage = oldMessage
    this.goApi = goApi
    this.user = () => userStore.credentials
  }

  user: () => { id: string, token: string } = () => { }

  old: AxiosInstance

  client: AxiosInstance

  oldMessage: AxiosInstance

  goApi: AxiosInstance

  operation = (type: OperationType, operation: any) => ({
    protocol: PROTOCOL_VERSION,
    version: 0,
    myID: this.user().id.toString(),
    myToken: this.user().token,
    requestOps: [{ op: type, ...operation }]
  })

  operations = (operations: any[]) => ({
    protocol: PROTOCOL_VERSION,
    version: 0,
    myID: this.user().id.toString(),
    myToken: this.user().token,
    requestOps: operations
  })

  fetch = async (call: Promise<AxiosResponse>) => {
    const res = await call
    if (res.status === 200) return res.data
    else return {}
  }
  genericErrorText = 'קיימת בעיה בשרת כרגע. אנא נסו מאוחר יותר.'
  /**
   *
   * @param res api response
   * @param handleError whether there should be a snackbar displayed if theres an error
   */
  genericResponse = (res: any, handleError: boolean = true): { success: boolean; data: any; error: string } => {

    try {
      let data = typeof res?.data === 'string' || res?.data instanceof String ? JSON.parse(res?.data) : res?.data

      if (res.status !== 200 || !data || data?.results[0]?.status !== 'ok') {
        if (handleError) {
          snackBar.showError(data?.results[0]?.errorDescription)
        }
        // snackBar.enqueueSnackbar(data?.results[0]?.errorDescription, {
        //   variant: 'error',
        //   anchorOrigin: {
        //     vertical: 'bottom',
        //     horizontal: 'center',
        //   }
        // })
        return { success: false, data: undefined, error: data?.results[0]?.errorDescription }
      } else {
        return { success: true, data: data, error: data?.results[0]?.errorDescription }
      }
    }
    catch (e) {
      console.log('genericResponse error ', e)
      return { success: false, data: undefined, error: e }
    }
  }
  genericResponseGo = (res: any, handleError: boolean = true): { success: boolean; data: any; error: string } => {

    try {
      let data = typeof res === 'string' || res instanceof String ? JSON.parse(res?.toString()) : res
      if ((res.statusCode !== 200 && data?.statusCode !== 'ok') || !data) {
        if (handleError) {
          snackBar.showError(data?.errorDescription)
        }
        return { success: false, data: undefined, error: data?.errors?.[0]?.errorDescription }
      } else {
        return { success: true, data: data?.data, error: data?.errorDescription }
      }
    }
    catch (e) {
      console.log('genericResponse error ', e)
      return { success: false, data: undefined, error: e }
    }
  }

  getConcileUsers = async (concileNumber: string) => {
    const res = await this.goFetcher(goEndPoints.getConcileUsers(concileNumber), Method.GET, null)
    const response = this.genericResponseGo(res, false)

    if (response && !response.success) {
      snackBar.showError(response.error)
      return []
    }

    return response.data;
  }

  // AUTH
  getCode = async (phone: string) => {
    const data = {
      protocol: PROTOCOL_VERSION,
      Username: phone,
      RegistrationType: 4
    }
    const res = await this.goFetcher(goEndPoints.login(), Method.POST, data)
    return this.genericResponseGo(res, false)
  }

  checkCode = async (code: string, tempToken: string): Promise<any | undefined> => {
    const data = {
      pinCode: code
    }
    try {
      const headers = { ['Authorization']: (tempToken && `Bearer ${tempToken}`) || '' }
      const res = await this.goFetcher(goEndPoints.verify(), Method.POST, data, headers)
      // const res = await this.goApi.post('/api/users/verify', data, { headers, withCredentials: trues })
      const genericResponse = this.genericResponseGo(res, false)
      return genericResponse
    } catch (e) {
      return ({ success: false, statusCode: 401, errorDescription: 'error', error: 'error' })
    }
  }
  getMessages = async (): Promise<Message[]> => {
    const res = await this.goFetcher(goEndPoints.getMessages(), Method.GET, null)
    const response = this.genericResponseGo(res, false)
    if (response && !response.success) {
      snackBar.showError(response.error)
      return []
    }
    const messages = (response?.data as Message[])?.map(el => (
      {
        ...el,
        created: new Date(el.created || new Date()),
        updated: new Date(el.updated || new Date()),
        expiry: el.expiry ? new Date(el.expiry) : undefined,
        schedule: el.schedule ? new Date(el.schedule) : undefined,
      }
    ))
    return messages || []
  }
  getMessageDetails = async (messageId: number) => {
    const res = await this.goFetcher(goEndPoints.getMessageDetails(messageId), Method.GET, null)
    return this.genericResponseGo(res, false)
  }
  getInfo = async (): Promise<undefined | Info> => {
    const res = await this.goFetcher(goEndPoints.getInfo(), Method.GET, null)
    if (res?.errors?.length) {
      console.log('getinfo error', res)
      snackBar.showError(res.errors[0]?.errorDescription)
    }
    return res?.data
  }
  getLabels = async (): Promise<string[]> => {
    const res = await this.goFetcher(goEndPoints.getLabels(), Method.GET, null)
    if (res?.errors?.length) {
      console.log('getinfo error', res)
      snackBar.showError(res.errors[0]?.errorDescription)
    }
    return res?.data || []
  }

  getCuttentUtc = async () => {
    const res = await this.goFetcher(goEndPoints.getUtc(), Method.GET, null)
    if (res?.errors?.length) {
      console.log('getinfo error', res)
      snackBar.showError(res.errors[0]?.errorDescription)
    }
    return res || null
  }


  createMessage = async (requestOps: MessageOp[], blobFile: File | File[] | null = null): Promise<number> => {
    const formData = new FormData()
    const json = {
      protocol: "V-1.0-WMC",
      type: "application/json",
      version: 0,
      requestOps: requestOps
    }

    formData.append('json', JSON.stringify(json))

    if (Array.isArray(blobFile)) {
      for (let x = 0; x < blobFile.length; x++) {
        formData.append("file[]", blobFile[x], "image.png");
      }
    }
    else if (blobFile) {
      formData.append("file", blobFile, "image.png")
    }

    // const response = await fetch(`${API_MEKOMI_BASE_URL}/api/entity/message`, {
    //   method: 'POST',
    //   headers: {
    //     'X-ZUMO-APPLICATION': X_ZUMO_APPLICATION!,
    //     'X-ZUMO-FEATURES': 'AJ',
    //     'Authorization': `Bearer ${userStore.jwt || ''}`
    //   },
    //   body: formData
    // })
    
    const res = await this.goFetcher(goEndPoints.createMessage(), Method.POST, formData) as { "createdId": number, "imagesURL": string[], "createdMessages": number, "statusCode": number, "errors": any[] }

    if (res.errors?.length) {
      snackBar.showError(res.errors[0]?.errorDescription)
    }
    return res.createdId
  }

  getCommunities = async (): Promise<{ "id": number, "name": string, "labels": string[] }[]> => {
    const res = await this.goFetcher(goEndPoints.getCommunities(), Method.GET, null)
    if (res.errors?.length) {
      snackBar.showError(res.errors[0]?.errorDescription)
    }
    return res.data?.filter((el: any) => el.labels != null) || []
  }
  logout = async (): Promise<any> => {
    const res = await this.goFetcher(goEndPoints.logout(), Method.GET, null)
    if (res.errors?.length) {
      snackBar.showError(res.errors[0]?.errorDescription)
    }
    return res
  }

  refreshToken = async (): Promise<any | undefined> => {
    const data = ''
    //const headers = { ['Cookie']: (userStore.jwt && `refreshToken=${userStore.jwt}`) || '' }
    const res = await this.goFetcher(goEndPoints.refreshToken(), Method.GET, data)
    if (res?.statusCode === 401) {
      userStore.logout()
      return res
    }
    const jwt = res?.data
    if (jwt && jwt.token) {
      //console.log("token refreshed ", jwt.token)
      userStore.setJwt(jwt.token)
    }
    return res
  }



  goFetcher = async (URL: string, method: Method = Method.POST, data: any, additionalHeaders: { [a: string]: string } = {}, iteration: number = 0): Promise<any> => {
    if (iteration >= 2) {
      return
    }
    const isFormData = data instanceof FormData
    const headers = new Headers({
      'X-ZUMO-APPLICATION': X_ZUMO_APPLICATION!,
      'X-ZUMO-FEATURES': 'AJ',
    })
    if (userStore.jwt) {
      headers.append('Authorization', `Bearer ${userStore.jwt || ''}`)
    }
    if (!isFormData) {
      headers.append('Content-Type', 'application/json')
    }
    Object.entries(additionalHeaders).forEach(el => {
      headers.append(el[0], el[1])
    })
    let body: string | undefined | FormData = data ? (data instanceof FormData ? data : JSON.stringify(data)) : undefined;
    body = (body === '{}') ? undefined : body
    const res = await fetch(API_MEKOMI_BASE_URL + URL, {
      method: method, // or 'PUT'
      headers: headers,
      body: body,
      credentials: 'include'
    })
    try {
      return await res.json()
    } catch (e) {
      console.log('caught error,', res.status)
      if (res.status === 401) {
        const fail = {
          success: false,
          statusCode: res.status,
          data: null,
          errorDescription: 'Error ' + res.status,
          error: 'Error ' + res.status
        }
        if (iteration === 1) {
          return fail
        }
        if (URL != goEndPoints.refreshToken() && URL != goEndPoints.logout()) {
          const refreshRes = await this.refreshToken()
          console.log('refresh res', refreshRes)
          if (refreshRes.success || refreshRes.statusCode === 200) {
            return await this.goFetcher(URL, method, data, additionalHeaders, ++iteration)
          }
        }
        return fail
      }
      if (res && res.status != 200) {
        return {
          success: false,
          statusCode: res.status,
          data: null,
          errorDescription: 'Error ' + res.status,
          error: 'Error ' + res.status
        }
      }
    }
  }
}

const API_MEKOMI_BASE_URL = process.env.REACT_APP_API_MEKOMI_BASE_URL
const X_ZUMO_APPLICATION = process.env.REACT_APP_X_ZUMO_APPLICATION


const oldApiMessage = axios.create({
  baseURL: `${API_MEKOMI_BASE_URL}/api/Message`,
  headers: {
    'X-ZUMO-APPLICATION': X_ZUMO_APPLICATION,
    'X-ZUMO-FEATURES': 'AJ'
  }
})

const oldApi = axios.create({
  baseURL: `${API_MEKOMI_BASE_URL}/api/Sync5`,
  headers: {
    'X-ZUMO-APPLICATION': X_ZUMO_APPLICATION,
    'X-ZUMO-FEATURES': 'AJ'
  }
})

const newApi = axios.create({
  baseURL: `${API_MEKOMI_BASE_URL}/api/webuser`,
  headers: {
    'X-ZUMO-APPLICATION': X_ZUMO_APPLICATION,
    'X-ZUMO-FEATURES': 'AJ'
  }
})
const goApi = axios.create({
  baseURL: `${API_MEKOMI_BASE_URL}`,
  headers: {
    'X-ZUMO-APPLICATION': X_ZUMO_APPLICATION,
    'X-ZUMO-FEATURES': 'AJ'
  }
})

const api = new Api(oldApi, newApi, oldApiMessage, goApi)
export default api
